<template>
  <!-- 评审会批复管理 -->
  <div class="contract-file-manage-list">
    <!-- 查询表单 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="resetFrom"
      ref="formData"
    >
    </base-form>

    <!-- 数据表格 -->
    <base-table
      class="main-page-table"
      :columns="columns"
      :showPage="true"
      :queryParas.sync="queryParas"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      :loadCount="loadCount"
      :api="progressApi"
      :getApi="'getPage'"
      ref="tableData"
      :webPage="false"
      :dataSource.sync="tableData"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <!-- 按钮组 -->
        <icon-button
          @click="edit(scope.row)"
          content="制作"
          icon="iconfont iconbianji3"
          v-if="scope.row.reviewStatus === 40||scope.row.reviewStatus === 50||scope.row.fileMakeStatus=== 10 || scope.row.fileMakeStatus=== 20"
        />

        <icon-button
          content="查看"
          icon="iconfont iconchakan"
          @click="info(scope.row)"

        />
         <icon-button
          content="预览"
          icon="iconfont iconyulan"
          @click="preview(scope.row)"
          v-if="scope.row.reviewStatus === 10 || scope.row.reviewStatus === 20 || scope.row.reviewStatus === 30 || scope.row.reviewStatus === 90"
        />
        <!-- <icon-button
          content="退回"
         icon="iconfont icona-tuihuipiliangtuihui"
           @click="back(scope.row)"
           v-if="scope.row.fileMakeStatus === 30&&scope.row.reviewStatus === 40"
        /> -->
      </template>
    </base-table>
     <back-dialog :visible.sync="visible" :queryTaskResultDTO='queryTaskResultDTO' :taskId='taskId' @handleFilter='handleFilter' />
      <pre-view :fileId="fileData.fileId" :isOpen='true' :fileType="fileData.fileFileSuffix" :count="count" />
  </div>
</template>
<script>
import PreView from '@/components/pre-view/pre-view.vue'
import BackDialog from '../../components/back-dialog.vue'
import baseForm from '@/components/common/base-form/base-form.vue'
import { fromConfig, columnsConfig } from './utils/config.js'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { reviewMeetingApi, progressApi } from '@/api/businessApi'
import { supplierApi } from '@/api/companyApi'
import Storage from '@/utils/storage'
export default {
  name: 'contractFileManage',
  components: { baseForm, BaseTable, IconButton, BackDialog, PreView },
  data () {
    return {
      fileData: {},
      count: 0,
      taskId: '',
      queryTaskResultDTO: {},
      visible: false,
      loadCount: 0,
      queryParas: {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1,
        processStatus: 'REVIEW'
      },
      disabled: false,
      tableData: [],
      gysData: []
    }
  },
  computed: {
    progressApi () {
      return progressApi
    },
    api () {
      return reviewMeetingApi
    },
    formConfig () {
      return fromConfig(this)
    },
    columns () {
      return columnsConfig(this)
    }
  },
  mounted () {
    this.gysList()
    this.handleFilter()
  },
  activated () {
    this.gysList()
    this.handleFilter()
  },
  methods: {
    // 预览
    preview (data) {
      if (data.fileId && data.fileName) {
        this.fileData.fileId = data.fileId
        this.fileData.fileFileSuffix = data.fileName.substr(data.fileName.lastIndexOf('.') + 1, 4)
        this.count++
      } else {
        this.warning('文件信息有误，稍后重试')
      }
    },
    // 查看按钮显示判断
    detailShow (data) {
      return (data.reviewStatus === 10 || data.reviewStatus === 20 || data.reviewStatus === 30 || data.reviewStatus === 90)
    },
    // 编辑按钮显示判断
    editShow (data) {
      return ((data.reviewStatus === 0 && data.fileMakeStatus !== 30) || data.reviewStatus === 40 || data.reviewStatus === 50)
    },
    // 退回
    back (row) {
      if (row.queryTaskResultDTO && row.queryTaskResultDTO.taskId) {
        const params = {
          businessId: row.queryTaskResultDTO.bizId,
          instanceId: row.queryTaskResultDTO.instanceId,
          taskId: row.queryTaskResultDTO.taskId,
          processName: row.processStatusId
        }

        progressApi.updatestatus(params).then(res => {
          if (res.success) {
            this.queryTaskResultDTO = row.queryTaskResultDTO
            this.queryTaskResultDTO.processStatus = this.queryParas.processStatus
            this.taskId = row.queryTaskResultDTO.taskId
            this.visible = true
          }
        })
      } else {
        this.warning('数据错误')
      }
    },
    // 查看详情
    info (data) {
      this.$router.push({
        path: '/business/approvalReviewMeetingInfo',
        query: { businessNo: data.businessNo }
      })
      data.type = '2'
      Storage.setLocal('contractInfo', data)
    },
    // 获取供应商数据
    gysList () {
      supplierApi.getList().then((res) => {
        if (res.success) {
          this.gysData = res.data
        }
      })
    },
    /**
     * 查询
     */
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    /**
     * 重置表单
     */
    resetFrom () {
      this.queryParas = {
        pageSize: 10, // 每页显示的数据
        pageIndex: 1,
        processStatus: 'REVIEW'
      }
    },
    // 查看详情
    edit (data) {
      this.$router.push({
        path: '/business/approvalProduction',
        query: { businessNo: data.businessNo }
      })
      data.type = '2'
      Storage.setLocal('contractInfo', data)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
